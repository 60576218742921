import React, { useState, useEffect, useContext, forwardRef } from 'react';
import PropTypes from 'prop-types';
import slugify from 'url-slug';
import Anchor from 'ToolboxComponents/commons/anchors/anchor/anchor';
import Favorite from 'ToolboxComponents/commons/buttons/favorite/favorite';
import { CardWrapper } from 'ToolboxComponents/commons/cards/results-product-card/results-product-card.style';
import Text from 'ToolboxComponents/commons/texts/text/text';
import DistanceConverted from 'ToolboxComponents/utils/formats/distances/distance';
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';
import { ThemeContext, LanguageContext, WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import formatSchedule from 'ToolboxUtils/web/formats/format-schedule';
import { set } from 'core-js/core/dict';


const ResultsProductCard = forwardRef(({ cardId, className, handleHover, product, toggleFavoriteActive, isCluster, isEvent, isSelectedProduct, targetPage, fromPage, highlightText }, ref) => {
  const theme = useContext(ThemeContext).state;
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [language] = useContext(LanguageContext).usePath();

  const [hasSchedule, setHasSchedule] = useState(false);
  const [scheduleStatus, setScheduleStatus] = useState('');
  const [schedulePath, setSchedulePath] = useState(null);
  const [scheduleHour, setScheduleHour] = useState({});
  const [scheduleDate, setScheduleDate] = useState({});
  const [scheduleDateString, setScheduleDateString] = useState('');
  
  const isRugby = product.extra && product.extra.isRugby;


  useEffect(() => {
    if (product.startTime === null && product.endTime === null) {
      setScheduleStatus('off');
      setSchedulePath('generic.circuits.closed');
      setScheduleHour(null);
      setScheduleDate(null);
      setScheduleDateString('');
      return;
    }
    setHasSchedule(true);
    const schedule = formatSchedule(
      isEvent, {
      startTime: product.startTime,
      endTime: product.endTime,
      language,
    });
    setScheduleStatus(schedule.status);
    setSchedulePath(schedule.message);
    setScheduleHour(schedule.hour);
    setScheduleDate(schedule.date);
    setScheduleDateString(schedule.dateString)
    // eslint-disable-next-line
  }, [product]);

  

  const isBiennale = product.idEvenementGenerique === 3834 || product.isBiennaleEvent ===  true
  
  return (
    <CardWrapper id={cardId} className={`product-card ${className}`} highlight={highlightText !== null} theme={theme} onMouseEnter={() => handleHover()}>
      <div ref={ref}>
      {highlightText !== null && <div className='highlight-block'>
        {highlightText}
      </div>} 
      <div className={`header  ${isBiennale ? 'macaron-biennale' : ''} ${isRugby ? 'macaron-rugby' : ''}`}>
        <Anchor code={targetPage} pageParams={`/${product.id}/${slugify(product.title, {lower: true})}`}  urlParams={{startTime: product.startTime, endTime: product.endTime}}>
          <ImageResize className='image' src={product.photo} alt={product.title} />
        </Anchor>
        {toggleFavoriteActive && <Favorite product={product} setFavorite={toggleFavoriteActive} fromPage={fromPage || 'listing'} />}
      </div>
      <Anchor code={targetPage} pageParams={`/${product.id}/${slugify(product.title, {lower: true})}`} urlParams={{startTime: product.startTime, endTime: product.endTime}}>
        <div className='content'>
          <div className='title'>{product.title}</div>
          {language === "fr" && product.secretCount > 0 && (
            <div className={`nb-secrets`}>
              {product.secretCount} secret
              {product.secretCount > 1 ? 's' : ''}
            </div>
          )}
          {product.teaser && (typeof product.teaser === 'string') &&
            <div className='description'>{product.teaser}</div>
          }
        </div>
        {isCluster ?
          <div className='footer'>
            <div className='distance'>
            <Text
              path={'generic.circuits.stops'}
              data={{ stopCount: product.stopCount }}
            />
            </div>
          </div>
        :
        <div className='footer'>
         {whiteLabel.isInZone &&  
          <div className='distance'>
            <span className='adn adn-location-arrow icon' />
            <DistanceConverted distance={product.distance} />
          </div>
         }
        {(isRugby || (!(whiteLabel.config.isSelectedProductScheduleHidden && isSelectedProduct) && hasSchedule && (scheduleHour !== null || scheduleDate !== null))) &&
          <div className='business-hours'>
            <span className='adn adn-clock icon' />
            {schedulePath !== null && (scheduleDateString ? 
              <span className={`is-off`}>{scheduleDateString}</span>
            : (
              <Text
                path={schedulePath}
                data={{ time: scheduleHour, date: scheduleDate }}
                className={`is-${scheduleStatus}`}
              />
            ))}
          </div>
        }
      </div>
        }
        
      </Anchor>
      </div>
    </CardWrapper>
  );
});

ResultsProductCard.propTypes = {
  cardId: PropTypes.string.isRequired,
  className: PropTypes.string,
  handleHover: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  toggleFavoriteActive: PropTypes.func.isRequired,
};

export default ResultsProductCard;
