import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext, AccountContext, WebappContext } from 'ToolboxUtils/web/context/context';
import PropTypes from 'prop-types';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import Text from 'ToolboxComponents/commons/texts/text/text';
import AccountNavBar from 'ToolboxComponents/webapp-light/pages/account-view/components/account-nav-bar/account-nav-bar';
import AccountProfileTab from 'ToolboxComponents/webapp-light/pages/account-view/components/account-profile-tab/account-profile-tab';
import AccountSecretsTab from 'ToolboxComponents/webapp-light/pages/account-view/components/account-secrets-tab/account-secrets-tab';
import ModalAddSecret from 'ToolboxComponents/webapp/modals/modal-secret/modal-secret';

import { AccountViewWrapper } from 'ToolboxComponents/webapp/pages/account-view/account-view.styles';

import tagNames from 'ToolboxComponents/webapp/pages/results-view/samples/sample-tag-names';

import ResultsView from 'ToolboxComponents/webapp/pages/results-view/results-view';
import Content from 'ToolboxComponents/webapp-light/pages/account-view/components/content/content';

const AccountView = props => {
  const { addFavorite, deleteFavorite, deleteAccount, sendActivationEmail, isSecretSaved, errorOnSecretSave, setSecretSaved } = props;
  const theme = useContext(ThemeContext).state;
  const [stateAccount] = useContext(AccountContext).usePath();
  const [selectedTab, setSelectedTab] = useContext(WebappContext).usePath('selectedTab',);

  const [account, setAccount] = useState(stateAccount);
  const [bannerTopPosition, setBannerTopPosition] = useState(0);
  const [externalShowMap, setExternalShowMap] = useState(false);
  const [fakeBannerHeight, setFakeBannerHeight] = useState('0px');
  const [isMobile, setIsMobile] = useState(null);
  const [isNavBarDisplayed, showNavBar] = useState(true);
  const [secretModalOpened, openAddSecretModal] = useState(false);
  const [tabSelected, setTab] = useState(selectedTab ? selectedTab : 'profile');

  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);
  useEffect(() => {
    if (externalShowMap && isMobile) {
      showNavBar(false);
    } else if (!externalShowMap && isMobile) {
      showNavBar(true);
    }
  }, [externalShowMap]);
  useEffect(() => {
    setTimeout(function() {
      if (!document.getElementById('main-nav') || !document.getElementById('nav-bar')) {
        return;
      }
      const mainNav = document.getElementById('main-nav');
      let top = document.getElementById('nav-bar').offsetHeight;
      if (mainNav) {
        top += mainNav.offsetHeight;
      }
      setBannerTopPosition(`${top}px`);
    }, 100);
  }, []);
  useEffect(() => {
    setTimeout(function() {
      setFakeBannerHeight(
        `${document.getElementById('banner').offsetHeight}px`,
      );
    }, 100);
  }, []);
  useEffect(() => {
    setAccount(stateAccount);
  }, [stateAccount]);
  useEffect(() => {
    if (selectedTab !== undefined) {
      setTab(selectedTab);
      setSelectedTab(undefined);
    }
  }, [selectedTab]);
  useEffect(() => {
    if (!secretModalOpened) {
      setSecretSaved(false);
    }
  }, [secretModalOpened]);
  return (
    <AccountViewWrapper
      theme={theme}
      bannerTopPosition={bannerTopPosition}
      fakeBannerHeight={fakeBannerHeight}
      isMobile={isMobile}
    >
      <div id='banner'>
        <div className={`container banner ${tabSelected}`}>
          {tabSelected === 'profile' ? (
            isMobile ? (
              <p className="hello">
                <Text
                  path={`page.account.hello`}
                  data={{ name: account.firstName }}
                />
              </p>
            ) : (
              <React.Fragment>
                <div className="centrer-helper"> <Text path={`page.account.signOut`} /></div>
                <p className="hello">
                  <Text
                    path={`page.account.hello`}
                    data={{ name: account.firstName }}
                  />
                </p>
                <div
                  onClick={() => props.logOut()}
                  className="logout"
                >
                  <i className="icon adn adn-power" />
                  <Text path={`page.account.signOut`} />
                </div>
              </React.Fragment>
            )
          ) : null}
          {tabSelected === 'secrets' && (
            <button
              type="button"
              className="add-secret"
              onClick={() => openAddSecretModal(true)}
              >
              {`${account.firstName}, chuchotez un nouveau secret `}
              <i className="icon adn adn-plus-circle" />
            </button>
          )}
        </div>
      </div>
      {tabSelected === 'profile' && (
        <Content>
          <div className="profile-tab-container">
            <AccountProfileTab
              account={account}
              secretCount={props.secrets.length}
              transports={props.transports}
              profiles={props.profiles}
              theme={theme}
              editFirstName={firstName => props.editFirstName(firstName)}
              editEmail={email => props.editEmail(email)}
              editPassword={passwords => props.editPassword(passwords)}
              editBiography={biography => props.editBiography(biography)}
              setTransport={transport => props.setTransport(transport)}
              setProfile={profile => props.setProfile(profile)}
              logOut={() => props.logOut()}
              openAddSecretModal={flag => openAddSecretModal(flag)}
              setProfilePicturePath={path => props.setProfilePicturePath(path)}
              fakeBannerHeight={fakeBannerHeight}
              deleteAccount={() => deleteAccount()}
              sendActivationEmail={sendActivationEmail}
            />
          </div>
        </Content>
      )}
      {tabSelected === 'secrets' && (
        <Content>
          <AccountSecretsTab
            account={account}
            theme={theme}
            secrets={props.secrets}
            openAddSecretModal={() => openAddSecretModal(true)}
            onDelete={id => props.onSecretDelete(id)}
            updateSecret={secret => props.updateSecret(secret)}
            fakeBannerHeight={fakeBannerHeight}
            isSecretSaved={isSecretSaved}
          />
        </Content>
      )}
      {secretModalOpened === true && (
        <ModalAddSecret
          onSubmit={secret => props.createSecret(secret.secret)}
          onClose={() => openAddSecretModal(false)}
          isSecretSaved={isSecretSaved}
          errorOnSecretSave={errorOnSecretSave}
        />
      )}
    </AccountViewWrapper>
  );
};

AccountView.propTypes = {
  secrets: PropTypes.array.isRequired,
  favorites: PropTypes.array.isRequired,
  transports: PropTypes.array.isRequired,
  profiles: PropTypes.array.isRequired,
  onSecretDelete: PropTypes.func.isRequired,
  editFirstName: PropTypes.func.isRequired,
  editEmail: PropTypes.func.isRequired,
  editPassword: PropTypes.func.isRequired,
  editBiography: PropTypes.func.isRequired,
  setTransport: PropTypes.func.isRequired,
  setProfile: PropTypes.func.isRequired,
  updateSecret: PropTypes.func.isRequired,
  createSecret: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
  addFavorite: PropTypes.func.isRequired,
  deleteFavorite: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  sendActivationEmail: PropTypes.func.isRequired,
  isSecretSaved: PropTypes.bool.isRequired,
  errorOnSecretSave: PropTypes.bool.isRequired,
  setSecretSaved: PropTypes.func.isRequired,
};

export default AccountView;
